import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../../components/headers/CustomHeader";
import {
    Button,
    CardBody,
    IconButton,
    Input,
    Spinner,
    Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import systemconfig from "../../../../systemconfig";
import {
    ADD_BUSINESS_DISCOUNT,
} from "../../../../GraphQl/mutations";

import { useHistory } from "react-router-dom";
import {
    GET_ALL_BUSINESS_CLASSES,
    GET_ALL_MANAGING_LEVIES,
    GET_COMPANY_LEVIES
} from "../../../../GraphQl/queries";
import { MdOutlineCheckBoxOutlineBlank, MdPolicy } from "react-icons/md";
import { IoMdCheckbox } from "react-icons/io";
import { DELETE_COMPANY_LEVY } from "../../../../GraphQl/mutations";


type FormDataType = {
    loading_id: string;

}[];


type BusinessClass = {
    id: string;
    name: string;
};

type LoadingType = {
    id: string;
    description: string;
    businessClassByBusinessClass: BusinessClass;
    name: string;
    status: string;
    created_at: string;
};

type GetLoadingsType = {
    levies: LoadingType[];
};



interface CompanyLoadingType {
    levy: LoadingType;
    id: string;
}

// Define the root response type
interface RegisteredLoadingsType {
    company_levies: CompanyLoadingType[];
}

const ManagePolicyLevies = () => {
    const history = useHistory();
    const getCompanyId = localStorage.getItem("company_id") as string;
    const getCompanyName = localStorage.getItem("company_name") as string;

    const [searchClassText, setSearchClassText] = useState("");
    const [searchLoadingsText, setSearchLoadingsText] = useState("");
    const [selectedSubClass, setSelectedSubClass] = useState<string | null>(null);
    const [selectedClassId, setSelectedClassId] = useState<string | null>(null);
    const [loadingSave, setLoading] = useState(false);

    const PAGE_SIZE = 10;

    const [
        addDiscount,
        { data: formReturnData, loading: formLoading, error: formError },
    ] = useMutation(ADD_BUSINESS_DISCOUNT);

    const [formData, setFormData] = useState<FormDataType>([]);

    const {
        loading: classLoading,
        error: classError,
        data: classData,
        refetch: classRefetch,
    } = useQuery(GET_ALL_BUSINESS_CLASSES);




    const {
        loading: registeredLoadingsLoading,
        error: registeredLoadingsError,
        data: registeredLoadingsData,
        refetch: registeredLoadingsRefetch,
    } = useQuery<RegisteredLoadingsType>(GET_ALL_MANAGING_LEVIES, {
        variables: {
            company_id: getCompanyId
        }
    });




    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        // Toggle the selectAll state
        setSelectAll(!selectAll);

        // If selectAll is true, set all items to the selected state
        if (!selectAll) {
            setFormData([
                ...formData,
                ...(paginatedLoadings ? paginatedLoadings.map((item: LoadingType) => ({ loading_id: item.id })) : [])
            ]);

        } else {
            setFormData([]);
        }
    };



    const handleSelectItem = (itemId: string) => {
        // Check if the item is already in the discount_id array
        const exists = formData?.some((loading: { loading_id: string }) => loading?.loading_id === itemId);

        if (exists) {
            // If it exists, remove it from the array

            const newLoading = formData.filter((loading: { loading_id: string }) => {
                return loading?.loading_id !== itemId
            })
            setFormData(newLoading);


        } else {
            // Otherwise, add it to the array
            setFormData([
                ...formData,
                {
                    loading_id: itemId
                }
            ]);

        }
    };





    const {
        loading: loadingsLoading,
        error: loadingsError,
        data: loadingsData,
        refetch: loadingsRefetch,
    } = useQuery<GetLoadingsType>(GET_COMPANY_LEVIES, {
        variables: {
            id: selectedClassId,
        },
        skip: selectedClassId == null
    });




    const addLoadingsToCompany = async () => {
        setLoading(true);


        try {
            const response = await fetch(`${systemconfig.baseUrl}/auth/add-levy-to-company?company_id=${getCompanyId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const req = await response.json();

            loadingsRefetch();
            registeredLoadingsRefetch()
            setFormData([]);
            selectAll && setSelectAll(!selectAll)

            toast.success("Class and levy Created Successfully");
        } catch (error: any) {

            toast.error(error?.message ?? "Failed to add levies");
        } finally {
            setLoading(false); // Ensure loading state is reset
        }
    };

    const handleCreate = async () => {
        if (formData.length <= 0) {
            toast.info("Select Loadings");
        } else {
            try {
                await addLoadingsToCompany(); // Await the async function
            } catch (err) {


                toast.error("Sorry, something went wrong");
            }
        }
    };


    const [searchText, setSearchText] = useState("");
    const [activePage, setActivePage] = useState(1);

    const filterData = (filter: CompanyLoadingType) => {
        const isTextMatch =
            filter?.levy?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
            || filter?.levy?.description?.toLowerCase()?.includes(searchText?.toLowerCase())
            || filter?.levy?.businessClassByBusinessClass?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
        return isTextMatch;
    };

    const filterClass = (filter: {
        name: string;
        description: string;
        status: string;
    }) => {
        const isTextMatch =
            filter?.description
                ?.toLowerCase()
                ?.includes(searchClassText?.toLowerCase()) ||
            filter?.name?.toLowerCase()?.includes(searchClassText?.toLowerCase()) ||
            filter?.status?.toLowerCase()?.includes(searchClassText?.toLowerCase());
        return isTextMatch;
    };

    const getLoadingsIds = (data: CompanyLoadingType[] | undefined) => {
        let ids = [] as string[];
        data?.map((values: CompanyLoadingType) => {
            ids.push(values?.levy?.id);
        });

        return ids;
    };

    const filterLoadings = (filter: LoadingType) => {
        let isTextMatch = false
        if (!getLoadingsIds(registeredLoadingsData?.company_levies)?.includes(filter?.id)) {
            isTextMatch = filter?.name
                ?.toLowerCase()
                ?.includes(searchLoadingsText?.toLowerCase()) ||
                filter?.description
                    ?.toLowerCase()
                    ?.includes(searchLoadingsText?.toLowerCase())
                ;
        }
        return isTextMatch
    };

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
        setActivePage(1);
    };

    const handleClassSearch = (e: any) => {
        setSearchClassText(e.target.value);
    };

    const handleSubClassSearch = (e: any) => {
        setSearchLoadingsText(e.target.value);
    };

    // Filtered and paginated data for the current page
    const paginatedData = registeredLoadingsData?.company_levies?.filter(filterData)
        .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);


    const paginatedBusinessClass =
        classData?.business_classes?.filter(filterClass);

    const paginatedLoadings = loadingsData?.levies?.filter(filterLoadings);

    const nextPage = () => {
        if (
            (activePage - 1) * PAGE_SIZE <
            (registeredLoadingsData?.company_levies?.filter(filterData).length || 0)
        ) {
            setActivePage(activePage + 1);
        }
    };

    const prevPage = () => {
        if (activePage > 1) {
            setActivePage(activePage - 1);
        }
    };


    const [DeleteLoadings, { error: deleteError, loading: DeleteLoading }] = useMutation(DELETE_COMPANY_LEVY, {
        onCompleted: (data) => {
            toast.success("You have successfully deleted loadings");
            loadingsRefetch();
            registeredLoadingsRefetch();


        },
        onError: (error) => {
            // Check for foreign key constraint violation
            if (error?.message.includes("Foreign key violation")) {
                toast.error("Cannot delete at this moment due to dependent data.");
            } else {
                // Show the actual error message for other errors
                toast.error(error?.message);
            }
        },
    });

    const handleDelete = (id: string) => {

        DeleteLoadings({
            variables: {
                id
            },
        });
    };

    return (
        <>
            <CustomHeader title="Manage Company Policy Setups" />
            <div className="mt-5 flex items-end gap-5 mb-5 ">
                <Button
                    onClick={() => {
                        history.push("/dashboard/setup/manage_policy");
                        ;
                    }}
                    size="md"
                    className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
                >
                    SubClass/Covertypes
                </Button>
                <Button
                    //   onClick={() => handleCreate()}
                    size="md"
                    className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
                >
                    Discount
                </Button>
                <Button
                    onClick={() => { history.push("/dashboard/setup/manage_benefit") }}
                    size="md"
                    className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
                >
                    Additional Benefit
                </Button>
                <Button
                    //    onClick={() => { history.push("/dashboard/setup/manage_policy_loadings"); }}
                    size="md"
                    className="capitalize flex flex-row items-center bg-red-200  tracking-widest text-sm font-medium"
                >
                    Loadings
                </Button>
                <Button

                    size="md"
                    className="capitalize flex flex-row items-center bg-red-400  tracking-widest text-sm font-medium"
                >
                    Levies & Charges
                </Button>
            </div>

            <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
                <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4 ">
                    <div className="capitalize rounded-b-xl mb-5">
                        <div className="bg-black p-3 text-white">
                            <Typography className="text-xl">Select Business Class</Typography>
                        </div>
                        <div className="p-3 bg-white">
                            <div className="w-full mr-3">
                                <Input
                                    crossOrigin="anonymous"
                                    label="Search"
                                    value={searchClassText}
                                    onChange={handleClassSearch}
                                    icon={<i className="fas fa-search text-base"></i>}
                                />
                            </div>
                        </div>
                        <table className=" bg-white w-full min-w-max table-auto text-left">
                            <thead className="">
                                <tr>
                                    {["Class"].map((head) => (
                                        <th
                                            key={head}
                                            className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                                        >
                                            <Typography
                                                color="black"
                                                className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedBusinessClass?.map((item: { id: string; name: string }) => (
                                    <tr
                                        onClick={() => {


                                            setSelectedClassId(item?.id);
                                            if (selectedClassId === item?.id) {
                                                setSelectedClassId(null);
                                            } else {
                                                setSelectedClassId(item?.id);
                                            }
                                        }}
                                        key={item.id}
                                        className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${selectedClassId === item?.id
                                            ? "bg-red-200 hover:bg-red-200"
                                            : "bg-white"
                                            }`}
                                    >
                                        <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal text-sm capitalize "
                                            >
                                                {item?.name}
                                            </Typography>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {classLoading && (
                            <div className="mt-10 flex items-center justify-center">
                                <Spinner className="w-1o h-10" />
                            </div>
                        )}
                    </div>

                    <div className="capitalize bg-white rounded-b-xl mb-5">
                        <div className="bg-black p-3 text-white">
                            <Typography className="text-xl">Select Levies</Typography>
                        </div>
                        <div className="p-3 ">
                            <div className="w-full mr-3 flex justify-center items-center ">
                                <div className=" text-black bg-gray-50/50 text-sm flex space-x-4 gap-2 w-[40%]">
                                    Select all
                                    <input
                                        type="checkbox"
                                        checked={selectAll} // Bind the select all state
                                        onChange={handleSelectAll} // Handle the select all functionality
                                    />
                                </div>
                                <Input
                                    crossOrigin="anonymous"
                                    label="Search"
                                    value={searchLoadingsText}
                                    onChange={handleSubClassSearch}
                                    icon={<i className="fas fa-search text-base"></i>}
                                />
                            </div>
                        </div>
                        <table className=" bg-white w-full min-w-max table-auto text-left">
                            <thead className="">
                                <tr>
                                    {["Loadings", "Description"].map((head) => (
                                        <th
                                            key={head}
                                            className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                                        >
                                            <Typography
                                                color="black"
                                                className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedLoadings?.map(
                                    (item: { id: string; name: string; description: string }) => {
                                        // Check if the current item is selected
                                        const isSelected = formData.some(loading => loading?.loading_id === item.id);

                                        return (
                                            <tr
                                                onClick={() => handleSelectItem(item.id)}
                                                key={item?.id}
                                                className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${isSelected ? "bg-white hover:bg-red-200" : "bg-white"}`}
                                            >
                                                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal text-sm capitalize flex gap-5"
                                                    >
                                                        {isSelected ? (
                                                            <IoMdCheckbox />
                                                        ) : (
                                                            <MdOutlineCheckBoxOutlineBlank />
                                                        )}
                                                        {item?.name}
                                                    </Typography>
                                                </td>
                                                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal text-sm capitalize"
                                                    >
                                                        {item?.description}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}


                            </tbody>
                        </table>

                        {loadingsLoading && (
                            <div className="mt-10 flex items-center justify-center">
                                <Spinner className="w-1o h-10" />
                            </div>
                        )}
                    </div>

                    {/* <div className="w-full my-2 bg-white p-5">
            <Typography
              variant="h6"
              color="blue-gray"
              className="mb-2 font-semibold text-[14px] w-1/3"
            >
              Description
            </Typography>
            <Textarea
              size="lg"
                value={formData.description}
                onChange={(event)=>    setFormData({...formData, description:event.target.value})}
              name="description"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
          </div> */}

                    <div className="mt-5 flex items-end justify-end">
                        <Button
                            onClick={() => handleCreate()}
                            size="sm"
                            className="capitalize flex flex-row items-center bg-red-400 font-normal"
                        >
                            <BiPlus size={30} />

                            {loadingSave ? "Creating..." : "Loadings"}
                        </Button>
                    </div>
                </div>

                {/*  */}
                <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
                    <div className="bg-black p-3 text-white">
                        <Typography className="text-xl">
                            List of
                            {" " + getCompanyName + " "}
                            Levies & Charges
                        </Typography>
                    </div>
                    <div className="p-3 bg-white rounded-b-xl">
                        <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
                            <div className="p-3">
                                <div className="w-full mr-3">
                                    <Input
                                        crossOrigin="anonymous"
                                        label="Search"
                                        value={searchText}
                                        onChange={handleSearch}
                                        icon={<i className="fas fa-search text-base"></i>}
                                    />
                                </div>
                            </div>
                            <table className=" bg-white w-full min-w-max table-auto text-center">
                                <thead className="">
                                    <tr>
                                        {[
                                            "no",
                                            "Loadings",
                                            "Business Class",
                                            "Description",
                                            "Action"
                                        ].map((head) => (
                                            <th
                                                key={head}
                                                className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                                            >
                                                <Typography
                                                    color="black"
                                                    className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData?.map(

                                        (item: CompanyLoadingType, index: number) => {

                                            const isLast = index === paginatedData?.length - 1;
                                            const classes = isLast
                                                ? "px-4 py-2  text-center"
                                                : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                                            return (
                                                <tr
                                                    key={index.toString()}
                                                    className="hover:bg-gray-100 transition-all even:bg-gray-200"
                                                    onClick={() => { }}
                                                >
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal text-xs capitalize "
                                                        >
                                                            {index + 1}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal text-xs capitalize "
                                                        >
                                                            {item?.levy?.name}

                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal text-xs capitalize "
                                                        >
                                                            {item?.levy?.businessClassByBusinessClass?.name}

                                                        </Typography>
                                                    </td>

                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal text-xs capitalize "
                                                        >
                                                            {item?.levy?.description}
                                                        </Typography>
                                                    </td>

                                                    {/* <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.rate}
                            </Typography>
                          </td> */}


                                                    <td className={classes}>
                                                        <div className="flex flex-row  justify-center items-center gap-x-2">
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    handleDelete(item?.id);

                                                                }}
                                                                color="red"
                                                                size="sm"
                                                            >
                                                                <BiTrash />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </CardBody>

                        {loadingsLoading && (
                            <div className="mt-10 flex items-center justify-center">
                                <Spinner className="w-1o h-10" />
                            </div>
                        )}

                        {paginatedData?.length! < 1 && !loadingsLoading && (
                            <div className="mt-10 flex items-center justify-center">
                                <Typography color="black" variant="h6">
                                    No data found
                                </Typography>
                            </div>
                        )}

                        {/* Paginator */}
                        {paginatedData && (
                            <div className="my-10 flex items-center justify-center">
                                <div className="flex items-center gap-8">
                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        onClick={prevPage}
                                        disabled={activePage === 1}
                                    >
                                        <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                                    </IconButton>
                                    <Typography color="gray" className="font-normal">
                                        Page <strong className="text-gray-900">{activePage}</strong>{" "}
                                        of{" "}
                                        <strong className="text-gray-900">
                                            {Math.ceil(
                                                (registeredLoadingsData?.company_levies?.filter(filterData).length ||
                                                    0) / PAGE_SIZE
                                            )}
                                        </strong>
                                    </Typography>
                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        onClick={nextPage}
                                        disabled={
                                            activePage ===
                                            Math.ceil(
                                                (registeredLoadingsData?.company_levies?.filter(filterData).length ||
                                                    0) / PAGE_SIZE
                                            )
                                        }
                                    >
                                        <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="h-20" />
        </>
    );
};

export default ManagePolicyLevies;
