import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface AuthState {
  userEmail: string
  password: string
}

// Retrieve state from localStorage
const loadState = (): AuthState => {
  const serializedState = localStorage.getItem("authState")
  if (serializedState) {
    try {
      return JSON.parse(serializedState)
    } catch (error) {
      console.error("Could not parse authState from localStorage:")
      localStorage.removeItem("authState")
    }
  }
  return { userEmail: "", password: "" }
}

const initialState: AuthState = loadState()

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => {
      state.userEmail = action.payload.userEmail
      state.password = action.payload.password
      // Save to localStorage whenever state is set
      localStorage.setItem("authState", JSON.stringify(state))
    },
  },
})

export const { setAuthState } = authSlice.actions
export default authSlice.reducer
