import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react"
import { NavLink, Link, useHistory } from "react-router-dom"
import Logo from "../../assets/logo.png"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {
  MdAdminPanelSettings,
  MdAutorenew,
  MdDashboard,
  MdOutlineDiscount,
  MdOutlinePolicy,
  MdPayments,
  MdReport,
  MdSettings,
  MdSubscriptions,
  MdWorkspacePremium,
} from "react-icons/md"
import { MdOutlinePhonelinkSetup } from "react-icons/md"
import { MdOutlineSecurity } from "react-icons/md"
import {
  FaClipboardUser,
  FaCoins,
  FaMapLocation,
  FaUserSecret,
  FaUserTie,
} from "react-icons/fa6"
import { ImMakeGroup } from "react-icons/im"
import { IoColorPaletteOutline } from "react-icons/io5"

import { TiPointOfInterestOutline } from "react-icons/ti"
import { GiCoins } from "react-icons/gi"
import { BiChevronDown, BiChevronRight } from "react-icons/bi"
import { IoSettings } from "react-icons/io5"
import { FaTruckLoading } from "react-icons/fa"
import { GrUserSettings } from "react-icons/gr"
import { UserType } from "../../types/user"
import { RootState } from "../../store/store"
import { useSelector } from "react-redux"

export function SidebarContent() {
  const storageData = localStorage.getItem("user")
  const [user, setUser] = useState<UserType>()
  const [openMessages, setOpenMessages] = useState<boolean>(false)
  const history = useHistory()
  const [open, setOpen] = useState<string>(
    localStorage.getItem("sidebarOpen") ?? "0"
  )
  const [openInner, setOpenInner] = useState(1)
  const handleOpenInner = (value: number) =>
    setOpenInner(openInner === value ? 0 : value)

  const [openHomeOwner, setOpenHomeOwner] = useState(1)
  const handleOpenHomeOwner = (value: number) =>
    setOpenHomeOwner(openHomeOwner === value ? 0 : value)

  const handleOpen = (value: string) => {
    setOpen(open === value ? "0" : value)
    localStorage.setItem("sidebarOpen", open === value ? "0" : value)
  }

  const handleOpenMessages = () => {
    setOpenMessages(!openMessages)
  }

  const { password, userEmail } = useSelector((state: RootState) => state.auth)
  // console.log(password, userEmail)

  const handleLogout = async () => {
    try {
      localStorage.clear()
      history?.push("/")
    } catch (error) {
      toast.error("Failed to log out")
    }
  }

  useEffect(() => {
    if (storageData) {
      setUser(JSON.parse(storageData))
    }
  }, [])

  if (userEmail === "glicoadmin@gmail.com" && password === "password") {
    return (
      <Card className="sidebar-container h-full overflow-y-scroll bg-black w-full max-w-[20rem] rounded-none  p-4">
        <div className="mb-1 p-4 flex items-center justify-center">
          <img src={Logo} alt="logo" className="h-12 object-cover" />
        </div>
        <List>
          <Accordion
            //  open={openInner === 1}
            open={openInner === 1 || openInner === 2}
            icon={
              <BiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  openInner === 1 || openInner === 2 ? "rotate-180" : ""
                }`}
                color="white"
              />
            }
          >
            <AccordionHeader
              onClick={() => handleOpenInner(1)}
              className={`border-b-0  ${
                (openInner === 1 || openInner === 2) && "bg-[#767879]"
              } p-1 py-3 text-sm text-white capitalize hover:bg-red-400 py-2.5 rounded-md`}
            >
              <ListItemPrefix>
                <MdOutlinePhonelinkSetup color="white" />
              </ListItemPrefix>
              <Typography color="white" className="text-sm mr-auto font-normal">
                Moto Setup
              </Typography>
            </AccordionHeader>
            <AccordionBody>
              <Link to={"/dashboard/setup/Vechicle_Make_Model"}>
                <ListItem
                  className={`${
                    history?.location?.pathname ===
                      "/dashboard/setup/Vechicle_Make_Model" && "bg-red-400"
                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                >
                  <ListItemPrefix>
                    <ImMakeGroup strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  Vechicle Make Model
                </ListItem>
              </Link>
              <Link to={"/dashboard/setup/Vechicle_Color"}>
                <ListItem
                  className={`${
                    history?.location?.pathname ===
                      "/dashboard/setup/Vechicle_Color" && "bg-red-400"
                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                >
                  <ListItemPrefix>
                    <IoColorPaletteOutline
                      strokeWidth={3}
                      className="h-3 w-5"
                    />
                  </ListItemPrefix>
                  Vechicle Color
                </ListItem>
              </Link>
              <Link to={"/dashboard/setup/vehicle-prefix"}>
                <ListItem
                  className={`${
                    history?.location?.pathname ===
                      "/dashboard/setup/vehicle-prefix" && "bg-red-400"
                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                >
                  <ListItemPrefix>
                    <MdSettings strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  Vehicle Prefix
                </ListItem>
              </Link>
              <Link to={"/dashboard/setup/driver_station"}>
                <ListItem
                  className={`${
                    history?.location?.pathname ===
                      "/dashboard/setup/driver_station" && "bg-red-400"
                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                >
                  <ListItemPrefix>
                    <MdSettings strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  Driver Station
                </ListItem>
              </Link>
            </AccordionBody>
          </Accordion>
          <div className="hover:bg-red-400 rounded-xl items-start justify-start">
          <div className="h-0.5 w-fit bg-white rounded-md" />
          <ListItem
            onClick={handleLogout}
            className="hover:bg-transparent hover:text-white text-white text-xs w-full"
          >
            <ListItemPrefix>
              <i className="fa fa-sign-out text-sm" aria-hidden="true"></i>
            </ListItemPrefix>
            Log Out
          </ListItem>
        </div>
        </List>
      </Card>
    )
  }

  return (
    <Card className="sidebar-container h-full overflow-y-scroll bg-black w-full max-w-[20rem] rounded-none  p-4">
      <div className="mb-1 p-4 flex items-center justify-center">
        <img src={Logo} alt="logo" className="h-12 object-cover" />
      </div>
      <List>
        {user && user?.role.name === "super_admin" && (
          <Link to={"/dashboard"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard" && "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
            >
              <ListItemPrefix>
                <MdDashboard />
              </ListItemPrefix>
              Overview
            </ListItem>
          </Link>
        )}
        <Link to={"/dashboard/client"}>
          <ListItem
            className={`${
              history?.location?.pathname === "/dashboard/client" &&
              "bg-red-400"
            } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
          >
            <ListItemPrefix>
              <FaUserTie />
            </ListItemPrefix>
            Client 360
          </ListItem>
        </Link>

        {/* <Link to={"/dashboard/payments"}>
          <ListItem
            className={`${
              history?.location?.pathname === "/dashboard/payments" &&
              "bg-red-400"
            } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
          >
            <ListItemPrefix>
              <MdPayments />
            </ListItemPrefix>
            All Payments
          </ListItem>
        </Link> */}

        {/* <List className="text-white bg-black -mt-2">
          <Accordion
            open={open === "p"}
            icon={
              <BiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === "p" ? "rotate-180" : ""
                }`}
                color="white"
              />
            }
          >
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/payments" &&
                "bg-red-400"
              } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
              selected={open === "p"}
            >
              <AccordionHeader
                onClick={() => handleOpen("p")}
                className="border-b-0 p-1 py-3 text-sm text-white capitalize"
              >
                <ListItemPrefix>
                  <MdPayments color="white" />
                </ListItemPrefix>
                <Typography
                  color="white"
                  className="text-sm mr-auto font-normal"
                >
              Payments Order
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1 text-white capitalize">
              <List className="p-0 z-50 bg-black">
                <Link to={"/dashboard/premium-payment-order"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/premium-payment-order" && "bg-red-400"
                    } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <MdPayments strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    premium payment order
                  </ListItem>
                </Link>
                <Link to={"/dashboard/subscription-payment-order"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/subscription-payment-order" && "bg-red-400"
                    } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <MdPayments strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    subscription payment order
                  </ListItem>
                </Link>
              </List>
            </AccordionBody>
          </Accordion>
        </List> */}

        <Link to={"/dashboard/subscriptions"}>
          <ListItem
            className={`${
              history?.location?.pathname === "/dashboard/subscriptions" &&
              "bg-red-400"
            } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
          >
            <ListItemPrefix>
              <MdSubscriptions />
            </ListItemPrefix>
            subscriptions
          </ListItem>
        </Link>

        <List className="text-white bg-black -mt-2">
          <Accordion
            open={open === "1" || open === "2" || open === "2.2"}
            icon={
              <BiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === "1" || open === "2" || open === "2.2"
                    ? "rotate-180"
                    : ""
                }`}
                color="white"
              />
            }
          >
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/setup" &&
                "bg-red-400"
              } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
              selected={open === "1" || open === "2" || open === "2.2"}
            >
              <AccordionHeader
                onClick={() => handleOpen("1")}
                className="border-b-0 p-1 py-3 text-sm text-white capitalize"
              >
                <ListItemPrefix>
                  <MdOutlinePhonelinkSetup color="white" />
                </ListItemPrefix>
                <Typography
                  color="white"
                  className="text-sm mr-auto font-normal"
                >
                  System Setup
                </Typography>
              </AccordionHeader>
                    </ListItem>
                    {user && user?.role.name === "super_admin" && (
                      <AccordionBody className="py-1 text-white capitalize">
                        <List className="p-0 z-50 ">
                          <Link to={"/dashboard/setup/location"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/location" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <FaMapLocation strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              Region/district/town
                            </ListItem>
                          </Link>
                         
                          <ListItem
                    className={` p-0 text-sm hover:bg-black-400 bg-black-400  py-2.5 ml-3 active:bg-black-400`}
                  >
<Accordion
                            //  open={openInner === 1}
                            open={openInner === 1 || openInner === 2}
                            icon={
                              <BiChevronDown
                                strokeWidth={2.5}
                                className={`mx-auto h-4 w-8 transition-transform ${
                                  openInner === 1 || openInner === 2 ? "rotate-180" : ""
                                }`}
                                color="white"
                              />
                            }
                          >
                            <AccordionHeader
                              onClick={() => handleOpenInner(1)}
                              className={`border-b-0  ${
                                (openInner === 1 || openInner === 2) && "bg-[#767879]"
                              } p-1 py-3 text-sm text-white capitalize hover:bg-red-400 py-2.5 rounded-md`}
                            >
                              <ListItemPrefix>
                                <MdOutlinePhonelinkSetup color="white"/>
                              </ListItemPrefix>
                              <Typography
                                color="white"
                                className="text-sm mr-auto font-normal "
                              >
                                Moto Setup
                              </Typography>
                            </AccordionHeader>
                            <AccordionBody>
                           
                              <Link to={"/dashboard/setup/Vechicle_Make_Model"}>
                                <ListItem
                                  className={`${
                                    history?.location?.pathname ===
                                      "/dashboard/setup/Vechicle_Make_Model" &&
                                    "bg-red-400"
                                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                >
                                  <ListItemPrefix>
                                    <ImMakeGroup strokeWidth={3} className="h-3 w-5" />
                                  </ListItemPrefix>
                                  Vechicle Make Model
                                </ListItem>
                              </Link>
                              <Link to={"/dashboard/setup/Vechicle_Color"}>
                                <ListItem
                                  className={`${
                                    history?.location?.pathname ===
                                      "/dashboard/setup/Vechicle_Color" && "bg-red-400"
                                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                >
                                  <ListItemPrefix>
                                    <IoColorPaletteOutline
                                      strokeWidth={3}
                                      className="h-3 w-5"
                                    />
                                  </ListItemPrefix>
                                  Vechicle Color
                                </ListItem>
                              </Link>
                              <Link to={"/dashboard/setup/vehicle-prefix"}>
                                <ListItem
                                  className={`${
                                    history?.location?.pathname ===
                                      "/dashboard/setup/vehicle-prefix" && "bg-red-400"
                                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                >
                                  <ListItemPrefix>
                                    <MdSettings strokeWidth={3} className="h-3 w-5" />
                                  </ListItemPrefix>
                                  Vehicle Prefix
                                </ListItem>
                              </Link>
                              <Link to={"/dashboard/setup/driver_station"}>
                                <ListItem
                                  className={`${
                                    history?.location?.pathname ===
                                      "/dashboard/setup/driver_station" && "bg-red-400"
                                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                >
                                  <ListItemPrefix>
                                    <MdSettings strokeWidth={3} className="h-3 w-5" />
                                  </ListItemPrefix>
                                  Driver Station
                                </ListItem>
                              </Link>
                            </AccordionBody>
                          </Accordion>
                  </ListItem>
                          
                          <ListItem className="hover:bg-black-400 active:bg-black-400">
                          <Accordion
                            //  open={openInner === 1}
                            open={openHomeOwner === 1 || openHomeOwner === 2}
                            icon={
                              <BiChevronDown
                                strokeWidth={2.5}
                                className={`mx-auto h-4 w-4 transition-transform ${
                                  openHomeOwner === 1 || openHomeOwner === 2
                                    ? "rotate-180"
                                    : ""
                                }`}
                                color="white"
                              />
                            }
                          >
                            <AccordionHeader
                              onClick={() => handleOpenHomeOwner(1)}
                              className={`border-b-0  ${
                                (openHomeOwner === 1 || openHomeOwner === 2) &&
                                "bg-[#767879]"
                              } p-1 py-3 text-sm text-white capitalize hover:bg-red-400 py-2.5 rounded-md`}
                            >
                              <ListItemPrefix>
                                <MdOutlinePhonelinkSetup color="white" />
                              </ListItemPrefix>
                              <Typography
                                color="white"
                                className="text-sm mr-auto font-normal"
                              >
                                Fire Insurance Setup
                              </Typography>
                            </AccordionHeader>
                            <AccordionBody>
                              <Link to={"/dashboard/setup/constructionType"}>
                                <ListItem
                                  className={`${
                                    history?.location?.pathname ===
                                      "/dashboard/setup/constructionType" &&
                                    "bg-red-400"
                                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                >
                                  <ListItemPrefix>
                                    <ImMakeGroup strokeWidth={3} className="h-3 w-5" />
                                  </ListItemPrefix>
                                  Construction Type
                                </ListItem>
                              </Link>

                              <Link to={"/dashboard/setup/useOfProperty"}>
                                <ListItem
                                  className={`${
                                    history?.location?.pathname ===
                                      "/dashboard/setup/useOfProperty" && "bg-red-400"
                                  } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                >
                                  <ListItemPrefix>
                                    <ImMakeGroup strokeWidth={3} className="h-3 w-5" />
                                  </ListItemPrefix>
                                  Use Of Property
                                </ListItem>
                              </Link>
                            </AccordionBody>
                          </Accordion>
                          </ListItem>

                      

                          <Link to={"/dashboard/setup/client_types"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/client_types" && "bg-red-400"
                              } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <FaClipboardUser />
                              </ListItemPrefix>
                              Client types
                            </ListItem>
                          </Link>
                          <Link to={"/dashboard/setup/client_registration"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/client_registration" && "bg-red-400"
                              } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <FaClipboardUser />
                              </ListItemPrefix>
                              Client registration
                            </ListItem>
                          </Link>
                          <Link to={"/dashboard/setup/riskclass"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/riskclass" && "bg-red-400"
                              } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <FaClipboardUser />
                              </ListItemPrefix>
                              Manage Risk class
                            </ListItem>
                          </Link>

                          <List className="text-white bg-black -mt-1">
                            <Accordion
                              open={open === "2.2"}
                              icon={
                                <BiChevronDown
                                  strokeWidth={2.5}
                                  className={`mx-auto h-4 w-4 transition-transform ${
                                    open === "2.2" ? "rotate-180" : ""
                                  }`}
                                  color="white"
                                />
                              }
                            >
                              <ListItem
                                className={`${
                                  history?.location?.pathname === "/dashboard/setup" &&
                                  "bg-red-400"
                                } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                                selected={open === "2.2"}
                              >
                                <AccordionHeader
                                  onClick={() => {
                                    handleOpen(open === "1" ? "2.2" : "1")
                                  }}
                                  className="border-b-0 p-1 py-3 text-sm text-white capitalize"
                                >
                                  <ListItemPrefix>
                                    <MdOutlinePolicy
                                      strokeWidth={3}
                                      className="h-3 w-5 text-white"
                                    />
                                  </ListItemPrefix>
                                  <Typography
                                    color="white"
                                    className="text-sm mr-auto font-normal"
                                  >
                                    Policy Setup
                                  </Typography>
                                </AccordionHeader>
                              </ListItem>
                              <AccordionBody className="py-1 text-white capitalize">
                                <List className="p-0 z-50 bg-black">
                                  <Link to={"/dashboard/setup/business-class"}>
                                    <ListItem
                                      className={`${
                                        history?.location?.pathname ===
                                          "/dashboard/setup/business-class" &&
                                        "bg-red-400"
                                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                    >
                                      <ListItemPrefix>
                                        <MdOutlinePolicy
                                          strokeWidth={3}
                                          className="h-3 w-5"
                                        />
                                      </ListItemPrefix>
                                      Class of business
                                    </ListItem>
                                  </Link>
                                  <Link to={"/dashboard/setup/peril"}>
                                    <ListItem
                                      className={`${
                                        history?.location?.pathname ===
                                          "/dashboard/setup/peril" && "bg-red-400"
                                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                    >
                                      <ListItemPrefix>
                                        <MdOutlinePolicy
                                          strokeWidth={3}
                                          className="h-3 w-5"
                                        />
                                      </ListItemPrefix>
                                      Perils
                                    </ListItem>
                                  </Link>
                                  <Link to={"/dashboard/setup/exclusion"}>
                                    <ListItem
                                      className={`${
                                        history?.location?.pathname ===
                                          "/dashboard/setup/exclusion" && "bg-red-400"
                                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                    >
                                      <ListItemPrefix>
                                        <MdOutlinePolicy
                                          strokeWidth={3}
                                          className="h-3 w-5"
                                        />
                                      </ListItemPrefix>
                                      Exclusions
                                    </ListItem>
                                  </Link>
                                  <Link to={"/dashboard/setup/cover-types"}>
                                    <ListItem
                                      className={`${
                                        history?.location?.pathname ===
                                          "/dashboard/setup/cover-types" && "bg-red-400"
                                      } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                                    >
                                      <ListItemPrefix>
                                        <MdOutlinePolicy
                                          strokeWidth={3}
                                          className="h-3 w-5"
                                        />
                                      </ListItemPrefix>
                                      Type of Cover
                                    </ListItem>
                                  </Link>
                                </List>
                              </AccordionBody>
                            </Accordion>
                          </List>

                          <Link to={"/dashboard/setup/premium-rates"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/premium-rates" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <MdWorkspacePremium
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Premium Rates Setup
                            </ListItem>
                          </Link>

                          <Link to={"/dashboard/setup/discounts"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/discounts" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <MdOutlineDiscount
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Discounts Setup
                            </ListItem>
                          </Link>

                          <Link to={"/dashboard/setup/additional_Benefits"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/additional_Benefits" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <MdOutlineDiscount
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Additional Benefit Setup
                            </ListItem>
                          </Link>

                          <Link to={"/dashboard/setup/loadings"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/loadings" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <FaTruckLoading strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              Loadings Setup
                            </ListItem>
                          </Link>

                          <Link to={"/dashboard/setup/commissions"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/commissions" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <TiPointOfInterestOutline
                                  strokeWidth={3}
                                  className="h-3 w-5"
                                />
                              </ListItemPrefix>
                              Commissions Setup
                            </ListItem>
                          </Link>

                          <Link to={"/dashboard/setup/levies"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/levies" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <FaCoins strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              Levies and Charges
                            </ListItem>
                          </Link>

                          <Link to={"/dashboard/setup/taxes"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/taxes" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <GiCoins strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              Taxes Setup
                            </ListItem>
                          </Link>

                          <Link to={"/dashboard/setup/subscriptions"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/subscriptions" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <GiCoins strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              company subscriptions
                            </ListItem>
                          </Link>

                          <Link to={"/dashboard/setup/roles"}>
                            <ListItem
                              className={`${
                                history?.location?.pathname ===
                                  "/dashboard/setup/roles" && "bg-red-400"
                              } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                            >
                              <ListItemPrefix>
                                <MdSettings />
                              </ListItemPrefix>
                              Permissions
                            </ListItem>
                          </Link>
                        </List>
                      </AccordionBody>
                    )}

            {user?.role.name !== "super_admin" && (
              <AccordionBody className="py-1 text-white capitalize">
                <Link to={"/dashboard/setup/client_registration"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/setup/client_registration" && "bg-red-400"
                    } text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <FaClipboardUser />
                    </ListItemPrefix>
                    Client registration
                  </ListItem>
                </Link>

                <Link to={"/dashboard/setup/subscriptions"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/setup/subscriptions" && "bg-red-400"
                    } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <GiCoins strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    company subscriptions
                  </ListItem>
                </Link>
              </AccordionBody>
            )}
          </Accordion>
        </List>

        {user?.role?.name === "super_admin" && (
          <Link to={"/dashboard/mange-admins"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/mange-admins" &&
                "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-3`}
            >
              <ListItemPrefix>
                <MdAdminPanelSettings color="white" />
              </ListItemPrefix>
              manage admins
            </ListItem>
          </Link>
        )}
        {user?.role?.name === "super_admin" && (
          <Link to={"/dashboard/external-users"}>
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/external-users" &&
                "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
            >
              <ListItemPrefix>
                <FaUserSecret color="white" />
              </ListItemPrefix>
              manage external users
            </ListItem>
          </Link>
        )}

        {/* <Link to={"/dashboard/messages"}>
          <ListItem
            className={`${history?.location?.pathname === "/dashboard/messages" &&
              "bg-red-400"
              } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
          >
            <ListItemPrefix>
              <MdReport color="white" />
            </ListItemPrefix>
            Messages
          </ListItem>
        </Link> */}
        <List className="text-white bg-black -mt-1">
          <Accordion
            open={openMessages}
            icon={
              <BiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === "2.2" ? "rotate-180" : ""
                }`}
                color="white"
              />
            }
          >
            <ListItem
              className={`${
                history?.location?.pathname === "/dashboard/messages" &&
                "bg-red-400"
              } p-0 text-sm hover:bg-red-400 hover:text-white text-white capitalize`}
              selected={openMessages}
            >
              <AccordionHeader
                // onClick={() => {
                //   handleOpen(open === "1" ? "2.2" : "1");
                // }}
                onClick={handleOpenMessages}
                className="border-b-0 p-1 py-3 text-sm text-white capitalize"
              >
                <ListItemPrefix>
                  <MdOutlinePolicy
                    strokeWidth={3}
                    className="h-3 w-5 text-white"
                  />
                </ListItemPrefix>
                <Typography
                  color="white"
                  className="text-sm mr-auto font-normal"
                >
                  Messages
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1 text-white capitalize">
              <List className="p-0 z-50 bg-black">
                <Link to={"/dashboard/messages/index"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/messages/index" && "bg-red-400"
                    } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <MdOutlinePolicy strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Message Type
                  </ListItem>
                </Link>
                <Link to={"/dashboard/messages/template"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/messages/template" && "bg-red-400"
                    } p-0 text-sm hover:bg-red-400 hover:text-white py-2.5 ml-3 text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <MdOutlinePolicy strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Message Template
                  </ListItem>
                </Link>
              </List>
            </AccordionBody>
          </Accordion>
        </List>

        <Link to={"/dashboard/reports"}>
          <ListItem
            className={`${
              history?.location?.pathname === "/dashboard/reports" &&
              "bg-red-400"
            } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
          >
            <ListItemPrefix>
              <MdReport color="white" />
            </ListItemPrefix>
            report
          </ListItem>
        </Link>

        <Link to={"/dashboard/settings"}>
          <ListItem
            className={`${
              history?.location?.pathname === "/dashboard/settings" &&
              "bg-red-400"
            } text-sm hover:bg-red-400 hover:text-white text-white capitalize -mt-1`}
          >
            <ListItemPrefix>
              <IoSettings color="white" />
            </ListItemPrefix>
            settings
          </ListItem>
        </Link>

        {/* <div className="absolute w-full bottom-7 "> */}
        <div className="hover:bg-red-400 rounded-xl items-start justify-start">
          <div className="h-0.5 w-fit bg-white rounded-md" />
          <ListItem
            onClick={handleLogout}
            className="hover:bg-transparent hover:text-white text-white text-xs w-full"
          >
            <ListItemPrefix>
              <i className="fa fa-sign-out text-sm" aria-hidden="true"></i>
            </ListItemPrefix>
            Log Out
          </ListItem>
        </div>
        {/* </div> */}
      </List>
    </Card>
  )
}
