import { Typography } from '@material-tailwind/react'
import React from 'react'
import type { SubscriptionType } from "./index";
import moment from "moment";


export default function ViewDetails({ content }: { content: SubscriptionType }) {
    return (
      <div>

<div className="overflow-scroll h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.id}
        </Typography>

        <div className="flex flex-col justify-between mb-2 gap-3 mt-4">
          
        <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Amount
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.amount}
            </Typography>
            
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Reference Number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.reference_number}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Insurance Company Name
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.insurer_company?.name}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
             Insurance Company Email
            </Typography>
            <Typography
              className="text-sm font-semibold text-black"
              color="blue-gray"
            >
              {content?.insurer_company?.email}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Insurance Company Phone
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.insurer_company?.phone}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
            {
                content?.intemediary != null &&
                (
                    <>
                                    <div>
                <Typography className="text-sm text-brand-gray " color="blue-gray">
                  Intemediary Name
                </Typography>
                <Typography
                  className="text-sm font-semibold  capitalize text-black"
                  color="blue-gray"
                >
                  {content?.intemediary?.name}
                </Typography>
              </div>
              <div>
                <Typography className="text-sm text-brand-gray " color="blue-gray">
                  Intemediary Email
                </Typography>
                <Typography
                  className="text-sm font-semibold  capitalize text-black"
                  color="blue-gray"
                >
                  {content?.intemediary?.email}
                </Typography>
              </div>
              <div>
                <Typography className="text-sm text-brand-gray " color="blue-gray">
                  Intemediary Phone
                </Typography>
                <Typography
                  className="text-sm font-semibold  capitalize text-black"
                  color="blue-gray"
                >
                  {content?.intemediary?.phone_number}
                </Typography>
              </div>
                    </>
                )

            }
         
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Status
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.status}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Subscription Name
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.subscription_rate?.name}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Subscription Rate
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.subscription_rate?.rate}
            </Typography>
          </div>

          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Policy Number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.policy?.policy_number}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Date Created
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {moment(content?.datecreated).format("LLL")}
            </Typography>
          </div>
        </div>

      </div>

      <div className="h-20" />
    </div>
    </div>
  )
}
