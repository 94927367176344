import React, { useState, useEffect } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { IconEdit } from "@tabler/icons-react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GET_ALL_BUSINESS_CLASSES, GETALLLOADINGS } from "../../../GraphQl/queries";

import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  CREATELOADING,
  DELETELOADING,
  UPDATELOADINGS,
} from "../../../GraphQl/mutations";
import { BusinessClassType } from "../../../types/class";

type LoadingType = {
  businessClassByBusinessClass:{
    id:string
    name:string
  }
  description: string;
  id: string;
  name: string;
  status: string;
};

type FormType = {
  name: string;
  description: string;
  class:string
};

const SetupLoading = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [editingItem, setEditingItem] = useState<LoadingType | null>(null);
  const PAGE_SIZE = 10;

  const {
    data: classes,
    refetch: refreshClass,
    loading: loadingClasses,
  } = useQuery<{
    business_classes: BusinessClassType[];
  }>(GET_ALL_BUSINESS_CLASSES);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormType>();

  const { data, loading, refetch } = useQuery<{
    loadings: LoadingType[];
  }>(GETALLLOADINGS);

  // console.log(data)

  const [CreateLoading, { loading: RequestLoading }] = useMutation(
    CREATELOADING,
    {
      onCompleted: (data) => {
        toast.success("Loading type Successfully added");
        reset();
        refetch();
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error creating loading type"),
    }
  );

  const [UpdateLoading] = useMutation(UPDATELOADINGS, {
    onCompleted: (data) => {
      toast.success("Loading type Updated Successfully");
      setEditingItem(null);
      reset();
      refetch();
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error updating loading type"),
  });

  const [DeleteLoading] = useMutation(DELETELOADING, {
    onCompleted: (data) => {
      toast.success("Loading type deleted successfully");
      refetch();
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting loading type"),
  });

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (filter: LoadingType) => {
    const isTextMatch =
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.description?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const paginatedData = data?.loadings
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.loadings?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onSubmit: SubmitHandler<FormType> = (formData) => {
    if (editingItem) {
      // Update existing loading type
      UpdateLoading({
        variables: {
          id: editingItem.id,
          name: formData.name,
          desc: formData.description,
        },
      });
    } else {
      // Create new loading type
      CreateLoading({
        variables: {
          name: formData.name,
          description: formData.description,
          bu_id: formData.class
        },
      });
    }
  };

  const deleteLoading = (id: string) => {
    try {
      DeleteLoading({
        variables: {
          id,
        },
      });
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting loading type");
    }
  };

  const handleEdit = (index: number) => {
    const itemToEdit = paginatedData![index];
    setEditingItem(itemToEdit);
    setValue("name", itemToEdit.name);
    setValue("description", itemToEdit.description);
  };

  useEffect(() => {
    if (!editingItem) {
      reset();
    }
  }, [editingItem, reset]);

  return (
    <>
      <CustomHeader title="Loadings" />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div className="bg-red-400 p-3">
            <Typography className="text-xl text-white">
              {editingItem ? "Edit Loading Type" : "Create Type of Loadings"}
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Class of Business
                    </Typography>

                    <select
                      id="Class"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("class", { required: true })}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        {loadingClasses ? "Loading..." : "Select class"}
                      </option>

                      {classes?.business_classes?.map((data) => (
                        <option key={data?.id} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Name
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("name", { required: true })}
                    />
                  </div>

                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("description", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-center bg-red-400 font-normal"
              >
                <BiPlus size={20} />
                {isSubmitting || RequestLoading
                  ? editingItem
                    ? "Updating..."
                    : "Creating..."
                  : editingItem
                  ? "Update Loading Type"
                  : "Create Loading Type"}
              </Button>
              {editingItem && (
                <Button
                  type="button"
                  size="sm"
                  className="capitalize flex flex-row items-center bg-gray-400 font-normal ml-2"
                  onClick={() => setEditingItem(null)}
                >
                  Cancel Edit
                </Button>
              )}
            </div>
          </form>
        </div>

        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">List of Loadings</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {["no","Class of Business", "Type of Loading", "Description", ""].map(
                      (head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.businessClassByBusinessClass?.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.description}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "loadings",
                                  JSON.stringify({
                                    id: item?.id,
                                    name: item?.name,
                                    description: item?.description,
                                  })
                                );
                                history.push(
                                  "/dashboard/setup/manage-loadings"
                                );
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Manage loading Rates
                            </Button>
                            <button
                              onClick={() =>
                                handleEdit((activePage - 1) * PAGE_SIZE + index)
                              }
                            >
                              <IconButton className="bg-[#4CAF4F]" size="sm">
                                <IconEdit stroke={2} size={13} />
                              </IconButton>
                            </button>
                            <IconButton
                              onClick={() => deleteLoading(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {paginatedData?.length! < 1 && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (data?.loadings?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (data?.loadings?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default SetupLoading;
