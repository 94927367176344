import {
  Button,
  Card,
  CardBody,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState, useMemo } from "react";
import { CustomDeleteModal, DetailDrawer } from "../../../components";
import useSWR from "swr";
import { BASEURL } from "../../../util";
import { AdminType, UpdateType } from "../../../types/admin";
import { ToastContainer, toast } from "react-toastify";
import { UserType } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { AddAdminModal } from "../../../components/forms/add-admin";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { useHistory } from "react-router-dom";
import { BiBlock } from "react-icons/bi";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GETALLINSURANCECOMPANIES,
  GETINSURANCECOMPANYSUBSCRIPTION,
  SUBSCRIPTIONPAYMENTAGGREGATE,
  TOTAL_SUBSCRIPTION_LIST,
} from "../../../GraphQl/queries";
import SubscriptionCard from "../../../components/Cards/SubscriptionCard";
import moment from "moment";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { isTemplateLiteral } from "typescript";
import { FaBedPulse } from "react-icons/fa6";
import axios from "axios";
import ViewDetails from "./ViewDetails";

export type SubscriptionType = {
  amount: number;
  reference_number: string;
  datecreated: string;
  id:string
  insurer_company: {
    name: string;
    phone: string;
    email: string;
  };
  intemediary: {
    email: string;
    id: string;
    name: string;
    phone_number: string;
  };
  status: string;
  subscription_rate: {
    id: string;
    rate: string;
    name: string;
  };
  policy: {
    policy_number: string;
  };
};

const SubscriptionsPage = () => {
  const history = useHistory();
  const [user, setUser] = useState<UserType>();
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<UpdateType>();
  const [selectRows, setSelectRows] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<string>("");
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)
  const [ViewDetailContent, setViewDetailContent] = useState<SubscriptionType>();


  const PAGE_SIZE = 10;

  const { data: commissionAggregate, loading: loadingAggregate } = useQuery<{
    aggregate: {
      sum: {
        amount: string;
      };
    };
  }>(SUBSCRIPTIONPAYMENTAGGREGATE);

  const { data: totalAggregateList, loading: loadingTotalAggregateList } =
    useQuery<{
      subscription_payment_aggregate: {
        aggregate: {
          sum: {
            amount: string;
          };
        };
      };
      subscription_payment: SubscriptionType[];
    }>(TOTAL_SUBSCRIPTION_LIST);

  const { data: insuranceCompanies, loading: loadingCompanies } = useQuery<{
    insurer_companies: {
      email: string;
      id: string;
      momo_details: string;
      bank_details: string;
      tin_number: string;
      phone: string;
      name: string;
      clientTypeByClientType: {
        name: string;
        id: string;
      };
      client_type: string;
      company_person: string;
      created_at: string;
    }[];
  }>(GETALLINSURANCECOMPANIES);

  const handleRowClick = (item: UpdateType) => {
    setSelectedRow(item);
    setOpenDrawer(true);
  };

  const handleFilterSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleSearch = (e: any) => {
 
    setSearchText(e.target.value);
    setActivePage(1);
  };

const handleSelectCompany = (e:any)=>{
  setCompanyId(e.target.value)
}

  const reset = () => {
    setSearchText("");
    setActivePage(1);
  };

  const filterData = (data: SubscriptionType) => {
    const isTextMatch =
      data?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.insurer_company?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.insurer_company?.email
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.insurer_company?.phone
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.intemediary?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.intemediary?.email
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.intemediary?.phone_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.policy?.policy_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.subscription_rate?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.subscription_rate?.rate
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const paginatedData = totalAggregateList?.subscription_payment
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (totalAggregateList?.subscription_payment?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };


  const handleSelectAll = ()=>{
    if (isSelectAll == false) {
      setIsSelectAll(true);
      const ids = [] as string[];
      paginatedData?.map((values: { id: string }) => {
        !selectRows?.includes(values?.id) && 
        ids.push(values?.id)
      });

      setSelectRows([...selectRows, ...ids])
      
    } else {
      setIsSelectAll(false);
      setSelectRows([])
     
    }

  }

  const handleSelectRow = (id: string) => {
  
    if (selectRows?.includes(id)) {
      if (isSelectAll == true) {
        setIsSelectAll(false);
      }
      const newData = selectRows?.filter((idx, index)=>{
        return idx !== id
      })
      setSelectRows([...newData])

    }
    else{
      setSelectRows([...selectRows,  id])
    }
  };


  const resolveSubscription = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/resolve-subscription?company_id=${companyId}`;
    try {
      const transformRows = selectRows.map((sub_id) => ({ sub_id }));
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      
      // Correct order of arguments for axios.post
      const response = await axios.post(url, transformRows, { headers });
      toast.success("Payment made successful");
      console.log('Response:', response.data);
    } catch (error) {
      toast.error("Sorry an error occurred");
      console.error('Error:', error);
    }
};

  return (
    <>
      {/* <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={handleDelete}
        loading={loading}
        title="Delete selected admin"
      /> */}
       <DetailDrawer
        onClose={() => setOpen(false)}
        open={open}
        title={ "Insurance companies" }
        children={<ViewDetails content={ViewDetailContent as SubscriptionType }/>  }
        size={450}
      />

      <CustomHeader title="Company Subscription" />
      <div className="mb-5 w-full grid-cols-1 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:lg:gap-4 lg:gap-5">
        <Card className="bg-brand-yellow rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between text-center">
            <div>
              <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                {loadingAggregate ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  "GH₵" +
                  (
                    totalAggregateList?.subscription_payment_aggregate
                      ?.aggregate?.sum?.amount ?? 0
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                Total subscriptions Paid
              </Typography>
            </div>
          </div>
        </Card>

        {insuranceCompanies?.insurer_companies?.map((item, index) => {
          return (
            <SubscriptionCard
              name={item?.name}
              company_id={item?.id}
              key={index}
            />
          );
        })}
      </div>

      <div className="mt-7 flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
        <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
          <div className="flex flex-row items-center w-full ">
            <Typography className="w-28">Filter by</Typography>
            <select
              value={companyId}
              onChange={handleSelectCompany}
              className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""} selected>
                Select filter
              </option>
              {insuranceCompanies?.insurer_companies?.map((item) => (
                <option key={item?.id} value={item?.id} label={item?.name}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <Button onClick={reset} className="capitalize">
              Reset
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              color="black"
              className="px-4 pb-4  font-bold text-brand-primary leading-none opacity-70 capitalize"
            >
              List of All Subscriptions
              {totalAggregateList?.subscription_payment && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {totalAggregateList?.subscription_payment?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="w-full">
            <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
            {isSelectAll && (
                    <Button
                      onClick={() => {
                        resolveSubscription()
                      }}
                      size="sm"
                      color="teal"
                      className="text-xs font-normal capitalize bg-brand-cyan w:xl"
                    >
                      {isPaymentLoading ? "loading..." : "Request to pay"}
                    </Button>
                  )}
 
              <div className="flex flex-col md:flex-row lg:flex-row gap-3 basis-10/12">
              

                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className="w-full bg-white min-w-max table-auto">
            <thead className="">
              <tr>
              <th className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4">
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={
                            isSelectAll
                          }
                        />
              </th>

                {[
                  "Transaction reference",
                  "Subscription amount",
                  "Subscription details",
                  "Policy Number",
                  "Company Details",
                  "date created",
                  "Status",
                  "Action"
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item, index: number) => {
                const isLast = index === []?.length - 1;

                const className = isLast
                  ? "px-2 py-3  text-center"
                  : "px-2 py-3 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={index.toString()}
                    onClick={() =>handleSelectRow(item?.id)}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >

                    <td className={className}>
                            <input
                              type="checkbox"
                            
                              checked={selectRows?.includes(item?.id)}
                              // disabled={
                              //   (item?.total_premium_arrears as any) > 0
                              // }
                            />
                          </td>


                    <td className={className}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.reference_number}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS
                        {(item?.amount ?? 0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.subscription_rate?.rate} %
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.policy.policy_number}
                      </Typography>
                    </td>
                    <td className={className}>
                      <div>
                        <Typography
                          className="text-xs   capitalize text-left"
                          color="blue-gray"
                        >
                          Bank: {item?.insurer_company?.name}
                        </Typography>
                        <Typography
                          className="text-xs   capitalize text-left"
                          color="blue-gray"
                        >
                          Branch: {item?.insurer_company?.phone}
                        </Typography>
                      </div>
                    </td>
                    <td className={className}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {moment(item?.datecreated).format("LLLL")}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography
                        variant="small"
                        color={
                          item?.status?.toLowerCase() === "paid"
                            ? "green"
                            : "red"
                        }
                        className="font-normal text-xs capitalize"
                      >
                        {item?.status}
                      </Typography>
                    </td>


                    <div className="flex justify-between whitespace-nowrap z-50 bg-white sticky right-0">
                            <td className={className}>
                              <div className="flex items-center gap-1">
                                <Button
                                  onClick={() => {setViewDetailContent(item);setOpen(true)}}
                                  size="sm"
                                  color="teal"
                                  className="text-xs font-normal capitalize bg-brand-teal"
                                >
                                  View details
                                </Button>

                                <Button
                                  onClick={() => {
                                    resolveSubscription()
                                  }}
                                  size="sm"
                                  disabled={
                                    !selectRows?.includes(item?.id)
                                  }
                                  color="teal"
                                  className="text-xs font-normal capitalize bg-brand-cyan"
                                >
                                  {isPaymentLoading
                                    ? "Loading"
                                    : "Request to pay"}
                                </Button>
                              </div>
                            </td>
                          </div>


                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {loadingTotalAggregateList && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}

        {paginatedData?.length! < 1 && !loadingTotalAggregateList && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                onClick={prevPage}
                color="white"
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (totalAggregateList?.subscription_payment?.filter(
                      filterData
                    ).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (totalAggregateList?.subscription_payment?.filter(
                      filterData
                    ).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SubscriptionsPage;
